// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-education-index-tsx": () => import("./../../../src/pages/education/index.tsx" /* webpackChunkName: "component---src-pages-education-index-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-fi-tsx": () => import("./../../../src/pages/index.fi.tsx" /* webpackChunkName: "component---src-pages-index-fi-tsx" */),
  "component---src-pages-page-2-index-en-tsx": () => import("./../../../src/pages/page-2/index.en.tsx" /* webpackChunkName: "component---src-pages-page-2-index-en-tsx" */),
  "component---src-pages-page-2-index-fi-tsx": () => import("./../../../src/pages/page-2/index.fi.tsx" /* webpackChunkName: "component---src-pages-page-2-index-fi-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-pages-thanks-index-tsx": () => import("./../../../src/pages/thanks/index.tsx" /* webpackChunkName: "component---src-pages-thanks-index-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-portfolio-index-tsx": () => import("./../../../src/templates/portfolio/index.tsx" /* webpackChunkName: "component---src-templates-portfolio-index-tsx" */)
}

